import { forwardRef, useEffect, useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import { GroupProps, useFrame, useThree } from '@react-three/fiber'
import { useGesture } from '@use-gesture/react'
import { DirectionalLight, Group, MathUtils, Vector2 } from 'three'

import usePageVisibility from '@/hooks/usePageVisibility'
import { PRIMITIVES_PATH } from '@/webgl/constants'

const Primitives = forwardRef<Group, GroupProps>((props, ref) => {
  const { nodes, materials } = useGLTF(PRIMITIVES_PATH) as any
  const cube = useRef<Group>(null!)
  const hexagon = useRef<Group>(null!)
  const pyramid = useRef<Group>(null!)
  const light = useRef<DirectionalLight>(null!)
  const [pointer] = useState(() => new Vector2())
  const clock = useThree((state) => state.clock)
  const documentHidden = usePageVisibility()

  useEffect(
    () => (documentHidden ? clock.stop() : clock.start()),
    [clock, documentHidden]
  )

  useFrame((_, delta) => {
    /**
     * cube update
     */
    const cubeGroup = cube.current
    const cubeMesh = cubeGroup.children[0]
    cubeGroup.scale.setScalar(0.13)
    cubeGroup.position.set(-0.3, -0.4, 0)

    cubeMesh.rotation.x += delta * 0.8
    cubeMesh.rotation.y += delta * 0.6
    cubeMesh.rotation.z += delta * 0.7

    cubeMesh.position.x = MathUtils.lerp(
      cubeMesh.position.x,
      -pointer.x * 0.6,
      delta * 1.5
    )
    cubeMesh.position.y = MathUtils.lerp(
      cubeMesh.position.y,
      -pointer.y * 0.6,
      delta * 1.5
    )

    /**
     * hexagon update
     */
    const hexagonGroup = hexagon.current
    const hexagonMesh = hexagonGroup.children[0]
    hexagonGroup.scale.setScalar(0.15)
    hexagonGroup.position.set(-0.7, 0.5, 0)

    hexagonMesh.rotation.x += delta * 0.6
    hexagonMesh.rotation.y += delta * 0.7
    hexagonMesh.rotation.z += delta * 0.8

    hexagonMesh.position.x = MathUtils.lerp(
      hexagonMesh.position.x,
      -pointer.x * 0.1,
      delta * 1.5
    )
    hexagonMesh.position.y = MathUtils.lerp(
      hexagonMesh.position.y,
      -pointer.y * 0.1,
      delta * 1.5
    )

    /**
     * pyramid update
     */
    const pyramidGroup = pyramid.current
    const pyramidMesh = pyramidGroup.children[0]
    pyramidGroup.scale.setScalar(0.13)
    pyramidGroup.position.set(0.3, 0.35, 0)

    pyramidMesh.rotation.x += delta * 0.8
    pyramidMesh.rotation.y += delta * 0.7
    pyramidMesh.rotation.z += delta * 0.6

    pyramidMesh.position.x = MathUtils.lerp(
      pyramidMesh.position.x,
      -pointer.x * -0.3,
      delta * 1.5
    )
    pyramidMesh.position.y = MathUtils.lerp(
      pyramidMesh.position.y,
      -pointer.y * 0.3,
      delta * 1.5
    )

    /**
     * light update
     */
    light.current.position.y = Math.sin(clock.getElapsedTime() * 0.5) * 2
  })

  useGesture(
    {
      onMove: ({ xy: [x, y] }) => {
        const mouseX = (x / window.innerWidth) * 2 - 1
        const mouseY = -(y / window.innerHeight) * 2 + 1
        pointer.set(mouseX, mouseY)
      }
    },
    {
      target: document.body
    }
  )

  return (
    <group ref={ref as any} dispose={null} {...props}>
      <group ref={cube as any} renderOrder={1}>
        <mesh
          name="cube"
          geometry={nodes['webgl-cube'].geometry}
          material={materials['white-flat']}
          material-transparent={true}
        />
      </group>
      <group ref={hexagon as any} renderOrder={1}>
        <mesh
          name="hexagon"
          geometry={nodes['werbgl-hexagon'].geometry}
          material={materials['white-flat']}
          material-transparent={true}
        />
      </group>
      <group ref={pyramid as any} renderOrder={1}>
        <mesh
          name="pyramid"
          geometry={nodes['webgl-pyramid'].geometry}
          material={materials['white-flat']}
          material-transparent={true}
        />
      </group>

      {/* <pointLight intensity={2} position={[0.5, 0, 5]} /> */}
      <directionalLight
        ref={light as any}
        intensity={2}
        position={[0.5, 0, 4]}
      />
    </group>
  )
})

export default Primitives
