import { useEffect, useState } from 'react'

const getBrowserSupportApi = () => {
  const document = window.document as any

  const hidden =
    typeof document.hidden !== 'undefined'
      ? 'hidden'
      : typeof document.msHidden !== 'undefined'
        ? 'msHidden'
        : 'webkitHidden'

  const visibilityChange =
    typeof document.hidden !== 'undefined'
      ? 'visibilitychange'
      : typeof document.msHidden !== 'undefined'
        ? 'msvisibilitychange'
        : 'webkitvisibilitychange'

  return { hidden, visibilityChange }
}

const usePageVisibility = () => {
  const [visibilityStatus, setVisibilityStatus] = useState(false)

  useEffect(() => {
    const { hidden, visibilityChange } = getBrowserSupportApi()

    function handleVisibilityChange() {
      setVisibilityStatus((document as any)[hidden])
    }

    document.addEventListener(visibilityChange, handleVisibilityChange)

    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange)
    }
  }, [])

  return visibilityStatus
}

export default usePageVisibility
