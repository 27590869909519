import {
  forwardRef,
  RefObject,
  useCallback,
  useImperativeHandle,
  useRef
} from 'react'
import { useEvent, useIsomorphicLayoutEffect } from 'react-use'
import { MeshProps, useThree } from '@react-three/fiber'
import gsap from 'gsap'
import {
  MathUtils,
  Mesh,
  PlaneGeometry,
  ShaderMaterial,
  Vector2,
  Vector4
} from 'three'

import { useRouterStore } from '@/store/router'
import { getViewportFromCameraFov } from '@/webgl/utils'

import { useLenisStore } from '@/contexts/lenis'
import { TRANSITION_ENTER_DURATION } from '@/contexts/transition'

export type PlaneMesh = Mesh<PlaneGeometry, ShaderMaterial>

export type WebGLDomPlaneProps = MeshProps & {
  target: RefObject<HTMLDivElement>
}

export type WebGLDomPlaneHandler = {
  instance: PlaneMesh
}

const WebGLDomPlane = forwardRef<WebGLDomPlaneHandler, WebGLDomPlaneProps>(
  ({ target, children, ...props }, ref) => {
    const get = useThree((state) => state.get)
    const plane = useRef<PlaneMesh>(null!)
    const previousScroll = useRef(0)

    const setupBounding = useCallback(() => {
      plane.current.userData.size = new Vector2()
      plane.current.userData.viewport = new Vector2()
      plane.current.userData.bbox = new Vector4()
      plane.current.userData.scale = new Vector2()
    }, [])

    const updateCamera = useCallback(() => {
      const camera = get().camera as any
      const canvas = get().gl.domElement
      const width = canvas.clientWidth
      const height = canvas.clientHeight

      camera.aspect = width / height
      camera.updateProjectionMatrix()
    }, [get])

    const updateBounding = useCallback(() => {
      if (!target.current) return
      const canvas = get().gl.domElement
      const bbox = target.current.getBoundingClientRect()
      const viewport = getViewportFromCameraFov(
        get().camera,
        plane.current.position.z
      )
      const size = { width: canvas.clientWidth, height: canvas.clientHeight }
      plane.current.userData.size = new Vector2(size.width, size.height)
      plane.current.userData.viewport = new Vector2(
        viewport.width,
        viewport.height
      )
      plane.current.userData.bbox = new Vector4(
        bbox.left,
        bbox.top,
        bbox.width,
        bbox.height
      )
      plane.current.userData.scale = new Vector2(
        viewport.width / size.width,
        viewport.height / size.height
      )
    }, [target, get])

    const updatePosition = useCallback(() => {
      const { size, viewport, bbox, scale } = plane.current.userData
      const x = MathUtils.mapLinear(
        bbox.x + bbox.z * 0.5,
        0,
        size.x,
        -viewport.x * 0.5,
        viewport.x * 0.5
      )
      const y = MathUtils.mapLinear(
        bbox.y + bbox.w * 0.5,
        0,
        size.y,
        viewport.y * 0.5,
        -viewport.y * 0.5
      )
      plane.current.scale.x = bbox.z * scale.x
      plane.current.scale.y = bbox.w * scale.y
      plane.current.position.x = x
      plane.current.position.y = y
    }, [])

    const updateMatrix = useCallback(() => {
      updateCamera()
      updateBounding()
      updatePosition()
    }, [updateCamera, updateBounding, updatePosition])

    useIsomorphicLayoutEffect(() => {
      const unsubscribe = useLenisStore.subscribe(
        (state) => state.scroll,
        (scroll) => {
          if (useRouterStore.getState().currentRoute !== '/') return
          const delta = previousScroll.current - scroll
          plane.current.userData.bbox.y += delta
          previousScroll.current = scroll
          updatePosition()
        }
      )

      return () => {
        unsubscribe()
      }
    }, [])

    useIsomorphicLayoutEffect(() => {
      const subscribe = useRouterStore.subscribe(
        (state) => state.currentRoute,
        (route) => {
          if (route !== '/') return
          gsap.delayedCall(TRANSITION_ENTER_DURATION, () => {
            plane.current.userData.bbox.y = 0
            previousScroll.current = 0
            updatePosition()
          })
        },
        { fireImmediately: false }
      )

      return () => {
        subscribe()
      }
    }, [])

    useEvent('resize', updateMatrix)

    useIsomorphicLayoutEffect(() => {
      if (target.current) {
        target.current.style.opacity = '0'
      }
    }, [])

    useIsomorphicLayoutEffect(() => {
      setupBounding()
      updateMatrix()
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        instance: plane.current
      }),
      []
    )

    return (
      <mesh ref={plane as any} {...props}>
        <planeGeometry args={[1, 1, 64, 64]} />
        {/* <circleGeometry args={[0.5, 64]} /> */}
        {children}
      </mesh>
    )
  }
)

export default WebGLDomPlane
