import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import { shaderMaterial } from '@react-three/drei'
import { extend, Object3DNode, useFrame, useThree } from '@react-three/fiber'
import { useGesture } from '@use-gesture/react'
import gsap from 'gsap'
import { Group, MathUtils, ShaderMaterial, TextureLoader, Vector2 } from 'three'

import usePageVisibility from '@/hooks/usePageVisibility'
import WebGLDomPlane, {
  WebGLDomPlaneHandler,
  WebGLDomPlaneProps
} from '@/webgl/dom/WebGLDomPlane'

import Primitives from '../components/Primitives'

type WebGLDomImageProps = Pick<WebGLDomPlaneProps, 'target'> & {
  onLoad?: () => void
}

const ImageShaderMaterial = shaderMaterial(
  {
    tDiffuse: null,
    uImage: new Vector2() as any,
    uResolution: new Vector2() as any,
    uTransition: 1
  },
  /* glsl */ `
    varying vec2 vUv;

    void main() {
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

      vUv = uv;
    }
  `,
  /* glsl */ `
    uniform sampler2D tDiffuse;
    uniform vec3 uColor;
    uniform vec2 uImage;
    uniform vec2 uResolution;
    uniform float uTransition;

    varying vec2 vUv;

    vec2 cover(vec2 st, vec2 resolution, vec2 image) {
      vec2 s = resolution;
      vec2 i = image;
      float rs = s.x / s.y;
      float ri = i.x / i.y;
      vec2 new = rs < ri ? vec2(i.x * s.y / i.y, s.y) : vec2(s.x, i.y * s.x / i.x);
      vec2 offset = (rs < ri ? vec2((new.x - s.x) / 2.0, 0.0) : vec2(0.0, (new.y - s.y) / 2.0)) / new;
      return st * s / new + offset;
    }

    vec3 sRGBToLinear(vec3 rgb) {
      vec3 low = rgb * (1.0 / 12.92);
      vec3 high = pow((rgb + 0.055) * (1.0 / 1.055), vec3(2.4));
      return vec3(
        rgb.x <= 0.04045 ? low.x : high.x,
        rgb.y <= 0.04045 ? low.y : high.y,
        rgb.z <= 0.04045 ? low.z : high.z
      );
    }

    void main() {
      vec2 uv = vUv;
      /* uv -= .5;
      uv *= 2.;
      uv += .5; */

      vec2 st = uv - .5;

      float dist = length(st);
      float ratio = 1./uResolution.y;
      dist = smoothstep(1.5 * ratio, 0., dist - ((0.5 * uTransition) - 1.0 * ratio));

      vec2 coverUV = cover(uv, uResolution, uImage);

      vec4 diffuse = texture2D(tDiffuse, coverUV);
      diffuse.rgb = sRGBToLinear(diffuse.rgb);

      vec3 color = diffuse.rgb;

      float opacity = dist;

      /**
       * output
       */
      gl_FragColor = vec4(color, opacity);

      #include <tonemapping_fragment>
	    #include <colorspace_fragment>
    }
  `
)

declare module '@react-three/fiber' {
  // eslint-disable-next-line no-unused-vars
  interface ThreeElements {
    imageShaderMaterial: Object3DNode<
      ShaderMaterial,
      typeof ImageShaderMaterial
    >
  }
}

const WebGLDomImage = forwardRef<WebGLDomPlaneHandler, WebGLDomImageProps>(
  ({ target, onLoad }, ref) => {
    useMemo(() => extend({ ImageShaderMaterial }), [])

    const root = useRef<Group>(null!)
    const plane = useRef<WebGLDomPlaneHandler>(null!)
    const shader = useRef<ShaderMaterial>(null!)
    const primitives = useRef<Group>(null!)
    const [pointer] = useState(() => new Vector2())
    const clock = useThree((state) => state.clock)
    const documentHidden = usePageVisibility()

    useEffect(
      () => (documentHidden ? clock.stop() : clock.start()),
      [clock, documentHidden]
    )

    useIsomorphicLayoutEffect(() => {
      const selector = gsap.utils.selector(target.current)
      const image = selector('img')[0] as HTMLImageElement

      const texture = new TextureLoader().load(image.src, (texture) => {
        const { instance } = plane.current
        const { bbox } = instance.userData
        shader.current.uniforms.tDiffuse.value = texture
        shader.current.uniforms.uResolution.value.set(bbox.z, bbox.w)
        shader.current.uniforms.uImage.value.set(
          texture.image.width,
          texture.image.height
        )

        onLoad && onLoad()
      })

      return () => {
        texture.dispose()
      }
    }, [])

    useFrame((_, delta) => {
      root.current.position.x = MathUtils.lerp(
        root.current.position.x,
        -pointer.x * 0.15,
        delta * 1.5
      )
      root.current.position.y = MathUtils.lerp(
        root.current.position.y,
        -pointer.y * 0.2,
        delta * 1.5
      )
    })

    useGesture(
      {
        onMove: ({ xy: [x, y] }) => {
          const mouseX = (x / window.innerWidth) * 2 - 1
          const mouseY = -(y / window.innerHeight) * 2 + 1
          pointer.set(mouseX, mouseY)
        }
      },
      {
        target: document.body
      }
    )

    useImperativeHandle(ref, () => plane.current, [])

    return (
      <group ref={root as any}>
        <WebGLDomPlane ref={plane} target={target}>
          <>
            <imageShaderMaterial
              ref={shader}
              depthTest={false}
              depthWrite={false}
              transparent={true}
            />
            <Primitives ref={primitives} />
          </>
        </WebGLDomPlane>
      </group>
    )
  }
)

export default WebGLDomImage
