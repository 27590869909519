import { RefObject, useRef } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'

import { isTouch } from '@/utils/device'
import WebGLDomImage from '@/webgl/dom/WebGLDomImage'
import { WebGLDomPlaneHandler } from '@/webgl/dom/WebGLDomPlane'
import { tunnel } from '@/webgl/dom/WebGLDomScene'
import WebGLDomShape from '@/webgl/dom/WebGLDomShape'

type WebGLDomState = {
  targetRef: RefObject<HTMLDivElement>
}

type WebGLDomProps = {
  variant?: 'image' | 'shape'
  imageRef?: RefObject<WebGLDomPlaneHandler>
  shapeRef?: RefObject<WebGLDomPlaneHandler>
  // eslint-disable-next-line no-unused-vars
  children: (state: WebGLDomState) => React.ReactNode
  onLoad?: () => void
}

const WebGLDom = ({
  variant,
  imageRef,
  shapeRef,
  children,
  onLoad
}: WebGLDomProps) => {
  const target = useRef<HTMLDivElement>(null!)

  useIsomorphicLayoutEffect(() => {
    if (isTouch) {
      onLoad && onLoad()
    }
  }, [])

  return (
    <>
      {!isTouch ? (
        <tunnel.In>
          {variant === 'image' ? (
            <WebGLDomImage ref={imageRef} target={target} onLoad={onLoad} />
          ) : variant === 'shape' ? (
            <WebGLDomShape ref={shapeRef} target={target} />
          ) : null}
        </tunnel.In>
      ) : null}

      {children({
        targetRef: target
      })}
    </>
  )
}

export default WebGLDom
