import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import { shaderMaterial } from '@react-three/drei'
import { extend, Object3DNode, useFrame, useThree } from '@react-three/fiber'
import { useGesture } from '@use-gesture/react'
import { Color, Group, MathUtils, ShaderMaterial, Vector2 } from 'three'

import usePageVisibility from '@/hooks/usePageVisibility'
import WebGLDomPlane, {
  WebGLDomPlaneHandler,
  WebGLDomPlaneProps
} from '@/webgl/dom/WebGLDomPlane'

type WebGLDomShapeProps = Pick<WebGLDomPlaneProps, 'target'>

const ShapeShaderMaterial = shaderMaterial(
  {
    uResolution: new Vector2() as any,
    uColor: new Color('#009FE3').convertLinearToSRGB() as any,
    uTransition: 1
  },
  /* glsl */ `
    varying vec2 vUv;

    void main() {
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

      vUv = uv;
    }
  `,
  /* glsl */ `
    uniform vec2 uResolution;
    uniform float uTransition;

    uniform vec3 uColor;

    varying vec2 vUv;
    
    vec3 sRGBToLinear(vec3 rgb) {
      vec3 low = rgb * (1.0 / 12.92);
      vec3 high = pow((rgb + 0.055) * (1.0 / 1.055), vec3(2.4));
      return vec3(
        rgb.x <= 0.04045 ? low.x : high.x,
        rgb.y <= 0.04045 ? low.y : high.y,
        rgb.z <= 0.04045 ? low.z : high.z
      );
    }

    void main() {
      vec2 uv = vUv;
      vec2 st = uv - .5;

      float dist = length(st);
      float ratio = 1./uResolution.y;
      dist = smoothstep(1.5 * ratio, 0., dist - ((0.5 * uTransition) - 1.0 * ratio));

      float gradient = smoothstep(0.0, 0.9, uv.y);
      vec3 color = mix(uColor, vec3(1.0), gradient);
      color = sRGBToLinear(color);

      float opacity = 0.9 - gradient;
      opacity *= dist;
      opacity *= uTransition;

      /**
       * output
       */
      gl_FragColor = vec4(color, opacity);

      #include <tonemapping_fragment>
	    #include <colorspace_fragment>
    }
  `
)

declare module '@react-three/fiber' {
  // eslint-disable-next-line no-unused-vars
  interface ThreeElements {
    shapeShaderMaterial: Object3DNode<
      ShaderMaterial,
      typeof ShapeShaderMaterial
    >
  }
}

const WebGLDomShape = forwardRef<WebGLDomPlaneHandler, WebGLDomShapeProps>(
  ({ target }, ref) => {
    useMemo(() => extend({ ShapeShaderMaterial }), [])

    const root = useRef<Group>(null!)
    const plane = useRef<WebGLDomPlaneHandler>(null!)
    const shader = useRef<ShaderMaterial>(null!)
    const [pointer] = useState(() => new Vector2())
    const clock = useThree((state) => state.clock)
    const documentHidden = usePageVisibility()

    useEffect(
      () => (documentHidden ? clock.stop() : clock.start()),
      [clock, documentHidden]
    )

    useIsomorphicLayoutEffect(() => {
      const { instance } = plane.current
      const { bbox } = instance.userData
      shader.current.uniforms.uResolution.value.set(bbox.z, bbox.w)
    })

    useFrame(({ clock }, delta) => {
      const { instance } = plane.current
      instance.rotation.z = clock.getElapsedTime() * 0.4
      root.current.position.x = MathUtils.lerp(
        root.current.position.x,
        -pointer.x * 0.1,
        delta * 1.5
      )
      root.current.position.y = MathUtils.lerp(
        root.current.position.y,
        -pointer.y * -0.15,
        delta * 1.5
      )
    })

    useGesture(
      {
        onMove: ({ xy: [x, y] }) => {
          const mouseX = (x / window.innerWidth) * 2 - 1
          const mouseY = -(y / window.innerHeight) * 2 + 1
          pointer.set(mouseX, mouseY)
        }
      },
      {
        target: document.body
      }
    )

    useImperativeHandle(ref, () => plane.current, [])

    return (
      <group ref={root as any}>
        <WebGLDomPlane ref={plane} target={target}>
          <shapeShaderMaterial
            ref={shader}
            depthTest={false}
            depthWrite={false}
            transparent={true}
          />
        </WebGLDomPlane>
      </group>
    )
  }
)

export default WebGLDomShape
